$primary: #60df00;

@font-face {
    font-family: 'Thumbs Down';
    font-style: normal;
    font-weight: 400;
    src: local('Thumbs Down'), url('../assets/fonts/Thumbs Down.otf') format('opentype');
}

@font-face {
    font-family: 'Lemon Milk';
    src: local('Lemon Milk'), url('../assets/fonts/Lemon Milk.ttf') format('truetype');
}

.font-lemon-milk {
    font-family: 'Lemon Milk', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}

.font-thumbs-down {
    font-family: 'Thumbs Down', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}

html,
body {
    background: #000;
    height: 100%;
    width: 100%;
    overflow: hidden;
    color: white;
    text-align: center;
}

.construction {
    height: 250px;
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%);
    display: block;
}

h2 {
    font-family: 'Thumbs Down', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}

h3 {
    font-family: 'Lemon Milk', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}

.btn {
    background-color: $primary;
    color: white;
    border-color: $primary;
    border-radius: 5px;
    padding: 1rem;
    font-size: 1.5rem;
    font-family: 'Thumbs Down', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
    text-shadow: 1px 1px black;
    cursor: pointer;

    &:hover {
        background-color: lighten($primary, 10%);
    }
}